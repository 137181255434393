.text {
  text-align: left;
  color: #650e3d;

  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 48px;
}
.centered_text {
  padding: 0 10px;
  color: #650e3d;
  text-align: center;
}
.small_text {
  height: 100%;
  width: 100%;
  text-align: left;
  font-size: 10px;
  line-height: 15px;
  color: #650e3d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.medium_text {
  height: 100%;
  width: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 15px;
  color: #650e3d;
  display: flex;
  align-items: center;
}

.hero {
  /* min-height: 100vh; */
  background: url(../assets/images/heroBGColored.png);
  background-repeat: no-repeat;
  background-size: cover;

  padding: 100px 0px 100px 0px;
}
.hero > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 100px 0px 100px 0px;
  margin-inline: max(calc(100px / 2), calc((5.8vw * 100) / 100));
  position: relative;
  /* border-radius: 31px;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #cdab49, rgba(0, 0, 0, 0)) 1 1%; */
}
.hero > div::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 31px;
  padding: 3px; /* control the border thickness */
  background: linear-gradient(to bottom, #cdab49, rgba(0, 0, 0, 0));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.hero > div > h1 {
  color: #cdab49;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(64px / 1.8), calc((5.8vw * 64) / 100));
  font-style: normal;
  font-weight: 300;
  line-height: max(calc(78.08px / 1.8), calc((5.8vw * 78.08) / 100));
  letter-spacing: max(calc(6.08px / 1.8), calc((5.8vw * 6.08) / 100));
  text-transform: uppercase;
  width: max(calc(1000px / 3), calc((5.8vw * 1000) / 100));
  margin: 0px;
}

.hero > div > h1 > span {
  color: #cdab49;
  font-family: Poppins;
  font-size: max(calc(48px / 2), calc((5.8vw * 48) / 100));
  font-style: normal;
  font-weight: 600;
  line-height: max(calc(78.08px / 1.8), calc((5.8vw * 78.08) / 100));
  letter-spacing: max(calc(4.56px / 1.8), calc((5.8vw * 4.56) / 100));
  text-transform: uppercase;
}

.hero > div > h2 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(32px / 1.8), calc((5.8vw * 32) / 100));
  font-style: normal;
  font-weight: 300;
  line-height: max(calc(47.68px / 1.8), calc((5.8vw * 47.68) / 100));
  width: max(calc(811px / 3), calc((5.8vw * 811) / 100));
  margin: 0px;
}

.hero > div > a {
  width: max(calc(263px / 1.8), calc((5.8vw * 263) / 100));
  height: max(calc(52px / 1.8), calc((5.8vw * 52) / 100));
  flex-shrink: 0;
  border: 0px;
  border-radius: max(calc(26px / 1.8), calc((5.8vw * 26) / 100));
  background: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  color: #650e3d;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(24px / 1.8), calc((5.8vw * 24) / 100));
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 20px;
}

.countdown_wrapper {
  display: flex;
  flex-direction: row;

  justify-content: center;
  /* width: max(calc(1200px / 3), calc((5.8vw * 1200) / 100)); */
  /* height: max(calc(186px / 1.8), calc((5.8vw * 186) / 100)); */

  margin-bottom: 50px;
}

.time_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: max(calc(160px / 4), calc((5.8vw * 160) / 100));
}

.time_text {
  color: #fff;
  font-family: Poppins;
  font-size: max(calc(18px / 2), calc((5.8vw * 18) / 100));
  font-style: normal;
  font-weight: 600;

  text-transform: uppercase;
}

.time {
  color: #fff;
  font-family: Poppins;
  font-size: max(calc(210px / 5), calc((5.8vw * 210) / 100));
  font-style: normal;
  font-weight: 100;
  margin-inline: max(calc(20px / 2), calc((5.8vw * 20) / 100));
  line-height: max(calc(230px / 3), calc((5.8vw * 230) / 100));
}

.joinUs div:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.joinUs div:nth-child(1) > p {
  display: flex;
  width: max(calc(582px / 1.8), calc((5.8vw * 582) / 100));
  height: max(calc(460px / 1.8), calc((5.8vw * 460) / 100));
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  /* padding-left: max(calc(60px / 1.8), calc((5.8vw * 60) / 100)); */
  color: #650e3d;
  font-family: Poppins;
  font-size: max(calc(32px / 1.8), calc((5.8vw * 32) / 100));
  font-style: normal;
  font-weight: 300;
  line-height: max(calc(50px / 1.8), calc((5.8vw * 50) / 100));
  margin: auto;
}

.joinUs div:nth-child(1) > img {
  max-width: max(calc(591px / 1.8), calc((5.8vw * 591) / 100));
  min-width: 50vw;
}
.joinUs div:nth-child(2) {
  background: #00091c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 20px 0px 40px 0px;
}

.joinUs div:nth-child(2) > h1 {
  color: #cdab49;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(40px / 1.8), calc((5.8vw * 40) / 100));
  font-style: normal;
  font-weight: 900;
  line-height: max(calc(41.8px / 1.8), calc((5.8vw * 41.8) / 100));
  letter-spacing: max(calc(3.8px / 1.8), calc((5.8vw * 3.8) / 100));
  text-transform: uppercase;
  margin-bottom: 0px;
}

.joinUs div:nth-child(2) > h2 {
  color: #cdab49;
  font-family: Poppins;
  font-size: max(calc(32px / 1.8), calc((5.8vw * 32) / 100));
  font-style: normal;
  font-weight: 275;
  line-height: max(calc(41.8px / 1.8), calc((5.8vw * 41.8) / 100));
  letter-spacing: max(calc(3.04px / 1.8), calc((5.8vw * 3.04) / 100));
  text-transform: uppercase;
  margin-top: 0px;
  text-align: center;
}

.joinUs div:nth-child(2) > a {
  width: max(calc(263px / 1.8), calc((5.8vw * 263) / 100));
  height: max(calc(52px / 1.8), calc((5.8vw * 52) / 100));
  flex-shrink: 0;
  display: flex;
  border-radius: 26px;
  background: #fff;
  border: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #650e3d;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(24px / 1.8), calc((5.8vw * 24) / 100));
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.youLearn {
  background: #650e3d;
  padding: 20px 0px 80px 0px;
}
.youLearn > h1 {
  color: #cdab49;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(64px / 2), calc((5.8vw * 64) / 100));
  font-style: normal;
  font-weight: 275;
  line-height: max(calc(66.88px / 1.8), calc((5.8vw * 66.88) / 100));
  letter-spacing: max(calc(6.08px / 1.8), calc((5.8vw * 6.08) / 100));
  text-transform: uppercase;
}

.carouselCard {
  width: max(calc(611px / 2.5), calc((5.8vw * 611) / 100)) !important;
  height: max(calc(362px / 2), calc((5.8vw * 362) / 100));

  margin: auto;
}

.carouselCard > h1 {
  color: #fff;
  font-family: Poppins;
  font-size: max(calc(40px / 2), calc((5.8vw * 40) / 100));
  font-style: normal;
  font-weight: 600;
  line-height: max(calc(47.4px / 1.8), calc((5.8vw * 47.4) / 100));
  text-transform: uppercase;
}

.carouselCard > p {
  color: #fff;
  font-family: Poppins;
  font-size: max(calc(32px / 2), calc((5.8vw * 32) / 100));
  font-style: normal;
  font-weight: 300;
  line-height: max(calc(47.4px / 1.8), calc((5.8vw * 47.4) / 100));
}

.arrowLeft,
.arrowRight {
  position: absolute;
  top: 30%;
  cursor: pointer;
  z-index: 100;
  opacity: 0.5;
}

.arrowLeft {
  left: max(calc(400px / 5), calc((5.8vw * 400) / 100));
}

.arrowRight {
  right: max(calc(400px / 5), calc((5.8vw * 400) / 100));
}

.arrowLeft:hover,
.arrowRight:hover {
  opacity: 1;
}

.program {
  display: flex;
  padding-inline: max(calc(200px / 4), calc((5.8vw * 200) / 100));
  padding-block: max(calc(100px / 4), calc((5.8vw * 100) / 100));
}
.programTitle {
  /* margin-right: max(calc(50px / 2), calc((5.8vw * 50) / 100)); */
  /* width: fit-content;
  max-width: 200px;
  display: flex; */
}

.programTitle > h1 {
  color: #650e3d;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(180px / 5), calc((5.8vw * 180) / 100));
  font-style: normal;
  font-weight: 200;
  line-height: max(calc(66.88px / 1.8), calc((5.8vw * 66.88) / 100));
  letter-spacing: max(calc(6.08px / 1.8), calc((5.8vw * 6.08) / 100));
  text-transform: uppercase;
}

.whenWhere {
  display: flex;
}
.whenWhere div:nth-child(1) {
  min-width: 45vw;

  background: #650e3d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
}

.whenWhere div:nth-child(1) > h1 {
  color: #fff;
  font-family: Poppins;
  font-size: max(calc(64px / 2), calc((5.8vw * 64) / 100));
  font-style: normal;
  font-weight: 700;
  line-height: max(calc(75.84px / 1.8), calc((5.8vw * 75.84) / 100));
  text-transform: uppercase;
  width: max(calc(370px / 2), calc((5.8vw * 370) / 100));
  margin: 0px;
}
.whenWhere div:nth-child(1) > span {
  width: max(calc(370px / 2), calc((5.8vw * 370) / 100));
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.whenWhere div:nth-child(1) > span > h2 {
  color: #fff;
  font-family: Poppins;
  font-size: max(calc(64px / 2), calc((5.8vw * 64) / 100));
  font-style: normal;
  font-weight: 300;
  line-height: max(calc(75.84px / 1.8), calc((5.8vw * 75.84) / 100));
  text-transform: uppercase;
  margin: 0px;
}
.whenWhere div:nth-child(1) > span > a {
  width: max(calc(205px / 1.8), calc((5.8vw * 205) / 100));
  height: max(calc(52px / 1.8), calc((5.8vw * 52) / 100));
  flex-shrink: 0;
  display: flex;
  border-radius: 26px;
  background: #fff;
  border: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #650e3d;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.whenWhere div:nth-child(2) {
  background-image: url(../assets/images/image2.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 55vw;
  min-height: max(calc(417px / 2), calc((5.8vw * 417) / 100));

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
}

.whenWhere div:nth-child(2) > h1 {
  color: #fff;
  font-family: Poppins;
  font-size: max(calc(64px / 2), calc((5.8vw * 64) / 100));
  font-style: normal;
  font-weight: 700;
  line-height: max(calc(75.84px / 2), calc((5.8vw * 75.84) / 100));
  text-transform: uppercase;
  width: max(calc(512px / 2), calc((5.8vw * 512) / 100));
  margin: 0px;
}
.whenWhere div:nth-child(2) > span {
  width: max(calc(512px / 2), calc((5.8vw * 512) / 100));
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.whenWhere div:nth-child(2) > span > h2 {
  color: #fff;
  font-family: Poppins;
  font-size: max(calc(64px / 2), calc((5.8vw * 64) / 100));
  font-style: normal;
  font-weight: 300;
  line-height: max(calc(75.84px / 2), calc((5.8vw * 75.84) / 100));
  text-transform: uppercase;
  margin: 0px;
}
.whenWhere div:nth-child(2) > span > a {
  width: max(calc(205px / 1.8), calc((5.8vw * 205) / 100));
  height: max(calc(52px / 1.8), calc((5.8vw * 52) / 100));
  flex-shrink: 0;
  display: flex;

  background: transparent;
  border-radius: 26px;
  border: 1px solid #fff;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.register {
  background: #f8f8f8;
  padding-bottom: 100px;
}
.register > h1 {
  color: #650e3d;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(64px / 1.8), calc((5.8vw * 64) / 100));
  font-style: normal;
  font-weight: 275;
  line-height: max(calc(66.88px / 1.8), calc((5.8vw * 66.88) / 100));
  letter-spacing: max(calc(6.08px / 1.8), calc((5.8vw * 6.08) / 100));
  text-transform: uppercase;

  width: max(calc(1099px / 3.1), calc((5.8vw * 1099) / 100));
  margin-inline: auto;
  margin-block: 0px;
  padding-block: max(calc(100px / 3), calc((5.8vw * 100) / 100));
}

.register > h1 > span {
  font-weight: 900;
}

.form {
  border-radius: 31px;
  background-color: white;
  margin-inline: max(calc(150px / 4), calc((5.8vw * 150) / 100));
  padding: max(calc(150px / 4), calc((5.8vw * 150) / 100));
}

.form > h1 {
  color: #650e3d;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(40px / 3), calc((5.8vw * 40) / 100));
  font-style: normal;
  font-weight: 700;
  line-height: max(calc(41.8px / 1.8), calc((5.8vw * 41.8) / 100));
  letter-spacing: max(calc(3.8px / 1.8), calc((5.8vw * 3.8) / 100));
  width: max(calc(1057px / 4), calc((5.8vw * 1057) / 100));
  text-transform: uppercase;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .joinUs div:nth-child(1) {
    flex-direction: column;
  }
  .joinUs div:nth-child(1) > img {
    margin: auto;
    width: 100vw;
    max-width: none;
  }
  .program {
    flex-direction: column;
    padding: 0px;
  }

  .whenWhere {
    flex-direction: column;
  }

  .whenWhere div:nth-child(1) {
    padding-block: 20px;
  }

  .hero > div {
    margin-inline: max(calc(20px / 2), calc((5.8vw * 20) / 100));
  }

  .arrowLeft > img,
  .arrowRight > img {
    width: 20px;
  }

  .carouselCard {
    display: block !important;
  }

  .joinUs div:nth-child(1) > p {
    margin-block: 20px;
  }

  .program {
    margin: 20px;
  }

  .form {
    margin-inline: max(calc(50px / 4), calc((5.8vw * 50) / 100));
    padding: max(calc(50px / 4), calc((5.8vw * 50) / 100));
  }

  .arrowLeft {
    left: max(calc(100px / 5), calc((5.8vw * 100) / 100));
  }

  .arrowRight {
    right: max(calc(100px / 5), calc((5.8vw * 100) / 100));
  }

  .arrowLeft > img,
  .arrowRight > img {
    width: 15px;
  }

  .whenWhere div:nth-child(1) > span > h2 {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 600px) {
  .programTitle > h1 {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
  }
}
