/* .link,
.link > a {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: max(calc(14px / 2), calc((5.8vw * 14) / 100)) !important;
  margin-inline: 15px;
} */
.registerButton {
  width: max(calc(159px / 1.8), calc((5.8vw * 159) / 100));
  height: max(calc(38px / 1.8), calc((5.8vw * 38) / 100));
  flex-shrink: 0;
  border-radius: max(calc(26px / 1.8), calc((5.8vw * 26) / 100));
  border: 1px solid #fff;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(16px / 1.8), calc((5.8vw * 16) / 100));
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  padding: 42px;
  background: #650e3d;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.footer > img {
  margin-bottom: 30px;
}

.social > a {
  margin-inline: max(calc(15px / 1.8), calc((5.8vw * 15) / 100));
}

.social > a > img {
  width: max(calc(25px / 1.8), calc((5.8vw * 25) / 100));

  height: max(calc(25px / 1.8), calc((5.8vw * 25) / 100));
}

.footer > h1 {
  margin-top: 30px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(14px / 1.8), calc((5.8vw * 14) / 100));
  font-style: normal;
  font-weight: 400;
  line-height: max(calc(14.63px / 1.8), calc((5.8vw * 14.63) / 100));
  letter-spacing: max(calc(1.33px / 1.8), calc((5.8vw * 1.33) / 100));
  text-transform: uppercase;
}

.footer > h1 > a {
  color: #fff;
  font-family: Poppins;

  font-style: normal;
  font-weight: 400;

  text-decoration-line: underline;
  text-transform: uppercase;
}

.footer > h2 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(11px / 1.8), calc((5.8vw * 11) / 100));
  font-style: normal;
  font-weight: 400;
  line-height: max(calc(11.495px / 1.8), calc((5.8vw * 11.495) / 100));
  letter-spacing: max(calc(1.045px / 1.8), calc((5.8vw * 1.045) / 100));
  text-decoration-line: underline;
  text-transform: uppercase;
  margin-top: 15px;
}

.footer > h2 > a {
  margin-inline: 15px;
  text-decoration: none;
  color: white;
}

.footer > h3 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: max(calc(10px / 1.8), calc((5.8vw * 10) / 100));
  font-style: normal;
  font-weight: 400;
  line-height: max(calc(10.45px / 1.8), calc((5.8vw * 10.45) / 100));
  letter-spacing: max(calc(0.95px / 1.8), calc((5.8vw * 0.95) / 100));
  text-transform: uppercase;
  margin-top: 25px;
}
