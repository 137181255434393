@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-dots > li button::before {
  color: rgba(205, 171, 73, 1) !important;
}

.slick-slide {
  margin: auto;
}
.slick-slide[data-index="0"] {
  /* mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  ); */
  /* -webkit-mask: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 0,
      rgba(0, 0, 0, 0.7) 50%,
      rgba(0, 0, 0, 0.2) 75%,
      rgba(0, 0, 0, 0.1) 100%
    )
    100% 50% / 100% 100% repeat-x; */
}
.slick-slide[data-index="2"] {
  /* mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  ); */
  /* -webkit-mask: linear-gradient(
      to left,
      rgba(0, 0, 0, 1) 0,
      rgba(0, 0, 0, 0.7) 50%,
      rgba(0, 0, 0, 0.1) 75%,
      rgba(0, 0, 0, 0.1) 100%
    )
    100% 50% / 100% 100% repeat-x; */
}

.MuiStepLabel-label {
  margin-left: max(calc(50px / 1.8), calc((5.8vw * 50) / 100)) !important;
  color: #cdab49 !important;
  font-family: Poppins !important;
  font-size: max(calc(36px / 1.8), calc((5.8vw * 36) / 100)) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: max(calc(42.66px / 1.8), calc((5.8vw * 42.66) / 100)) !important;
  text-transform: uppercase !important;
}

.MuiStepContent-root > div > div > div > p {
  margin-left: max(calc(50px / 1.8), calc((5.8vw * 50) / 100)) !important;
  color: #650e3d !important;
  font-family: Poppins !important;
  font-size: max(calc(36px / 1.8), calc((5.8vw * 36) / 100)) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: max(calc(42.66 / 1.8), calc((5.8vw * 42.66) / 100)) !important;
  text-transform: uppercase !important;
  margin-bottom: 10px;
}

.MuiStepContent-root > div > div > div > p > span {
  color: #650e3d !important;
  font-family: Poppins !important;
  font-size: max(calc(36px / 1.8), calc((5.8vw * 36) / 100)) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: max(calc(42.66 / 1.8), calc((5.8vw * 42.66) / 100)) !important;
  text-transform: uppercase !important;
}

@media only screen and (max-width: 600px) {
  .slick-slide > div {
    margin: auto;
  }
}

.arabic_version {
  direction: rtl !important;
}
.arabic_version h1,
.arabic_version h2,
.arabic_version h3,
.arabic_version h4,
.arabic_version h5,
.arabic_version p,
.arabic_version span {
  letter-spacing: 0px !important;
}

.arabic_version .slick-slide {
  text-align: right;
}
